'use client';

import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { useParams, useRouter } from 'next/navigation';

import type { CommunityMember, User } from '@zealy/queries';
import type { ClaimQuestOutput, GetQuestOutput, RetriableErrorCode } from '@zealy/utils';
import {
  FetchError,
  getMe,
  questKeys,
  useAuthenticatedUser,
  useClaimQuest,
  usersKeys,
  useSubscriptionStatus,
  useTestClaimQuest,
} from '@zealy/queries';
import { claimErrorMessage, claimQuestFormSchema, RETRIABLE_ERROR_CODES } from '@zealy/utils';

import type { ClaimValue } from '#components/Tasks/Tasks.types';
import {
  ErrorContext,
  useErrorContext,
} from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/FormError.context';
import { queryClient } from '#app/QueryProvider';
import { TrackingPixel } from '#components';
import { TipTapToContent } from '#components/Editor';
import { getQuestStatus } from '#components/QuestCard/components/QuestStatus/QuestStatus.utils';
import { RewardMethodDetails } from '#components/RewardMethodDetails/RewardMethodDetails';
import { Tasks } from '#components/Tasks';
import {
  useMissingAuth,
  useZealyConnectStatusForQuestAndTasks,
} from '#components/Tasks/Tasks.hooks';
import { toast } from '#components/Toaster';
import { useKeyboardShortcut } from '#hooks/useKeyboardShortcut';
import { revalidateTags } from '#utils/serverAction';

import { useQuestNavigation } from '../../../../_components/Board.hooks';
import { ClaimActions } from './ClaimActions';
import { Rewards } from './Rewards';
import { States } from './States';
import { StatusBadges } from './StatusBadges';

export type ClaimQuestProps = {
  quest: GetQuestOutput;
  isPreview?: boolean;
};

const shouldShowCelebration = (user: CommunityMember | undefined, quest: GetQuestOutput) => {
  // First XP won
  const xpReward = quest.rewards.find(reward => reward.type === 'xp');
  if (user?.xp === 0 && !!xpReward?.value) {
    return true;
  }
  // For other rewards than XP and roles
  const nonBasicRewards = quest.rewards.find(
    reward => reward.type !== 'xp' && reward.type !== 'role' && reward.method?.type === 'all',
  );
  if (nonBasicRewards) return true;

  // If won a special reward
  const specialRewardMethod = quest.rewards.find(
    reward => reward.method?.type === 'fcfs' && reward.status !== 'ended',
  );
  return !!specialRewardMethod;
};

const ClaimQuest = ({ quest, isPreview }: ClaimQuestProps) => {
  const { data: user } = useAuthenticatedUser<CommunityMember & User>();
  const claimQuest = useClaimQuest(quest?.categoryId, quest?.id);
  const t = useTranslations('quest');
  const { data: subscriptionData } = useSubscriptionStatus();

  const { subdomain } = useParams<{
    questId?: string;
    moduleId?: string;
    subdomain: string;
  }>();
  const router = useRouter();
  const { nextQuest } = useQuestNavigation(quest.id, true);

  const missingAuth = useMissingAuth(quest?.tasks || [], quest?.rewards || [], !isPreview);
  const { missing: zealyNotConnected } = useZealyConnectStatusForQuestAndTasks(
    quest.id,
    quest?.tasks,
  );
  const { isValid } = useErrorContext();
  const formMethods = useForm<Record<string, ClaimValue>>({
    resolver: zodResolver(claimQuestFormSchema),
    mode: 'onChange',
  });

  const testClaimQuest = useTestClaimQuest({
    questId: quest.id ?? 'save-quest-to-generate',
    tasks: quest.tasks,
    subdomain,
  });

  const trigger = formMethods.trigger;

  // We need this to make sure that it unlocks for empty quests or quests with no input
  useEffect(() => {
    trigger();
  }, [trigger, user]);

  const status = getQuestStatus(quest, false);

  const isValidCheating = user?.cheatStatus !== 'restricted';

  const isQuestValid = useMemo(
    () =>
      isValid &&
      !!user &&
      formMethods.formState.isValid &&
      missingAuth.length === 0 &&
      zealyNotConnected.length === 0 &&
      status.canClaim &&
      isValidCheating &&
      (!subscriptionData || !subscriptionData.isLimitReached),
    [
      isValid,
      user,
      formMethods.formState.isValid,
      missingAuth.length,
      zealyNotConnected.length,
      status.canClaim,
      isValidCheating,
      subscriptionData,
    ],
  );

  const setValidationErrors = (taskValidations?: ClaimQuestOutput['taskValidations']) => {
    taskValidations?.forEach(taskValidation => {
      if (taskValidation.status === 'error') {
        formMethods.setError(taskValidation.taskId, {
          message: t(
            `claim-error.${taskValidation.error.code}`,
            (taskValidation.error as any).context,
          ),
          type:
            taskValidation.error.code === 'API_FAILED'
              ? taskValidation.error.context?.requestId
              : taskValidation.error.code,
        });
      }
    });
  };

  const onSubmit = () => {
    const taskValues = Object.values(formMethods.getValues());
    isQuestValid &&
      !isPreview &&
      claimQuest.mutate(
        {
          taskValues,
        },
        {
          onSuccess: ({ status }) => {
            if (status === 'success') {
              revalidateTags([`user:${getMe()}`]);

              // Invalidate to unlock linked quests
              queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });

              if (!shouldShowCelebration(user, quest)) {
                if (nextQuest)
                  router.push(
                    `/cw/${subdomain}/questboard/${nextQuest.categoryId}/${nextQuest.id}`,
                  );
              }
            }
          },
          onError: e => {
            if (e instanceof FetchError) {
              // We invalidate in case there is a cooldown
              // Full board since we want to show the cooldown on the board & module view as well
              if (quest.retryAfter !== 0)
                queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });

              const error: string = e.response?._data?.error || e.response?._data?.message;

              if (e.response?._data?.taskValidations) {
                setValidationErrors(
                  e.response?._data?.taskValidations as ClaimQuestOutput['taskValidations'],
                );
              }

              if (
                [
                  claimErrorMessage.RECONNECT_TWITTER_ACCOUNT,
                  claimErrorMessage.TWITTER_ACCOUNT_NOT_LINKED,
                  claimErrorMessage.TWITTER_ACCOUNT_SUSPENDED,
                  claimErrorMessage.TOO_MANY_INCORRECT_SUBMISSIONS,
                ].includes(error)
              ) {
                queryClient.invalidateQueries({ queryKey: usersKeys.user('me', subdomain) });
              }

              if (RETRIABLE_ERROR_CODES.includes(error as RetriableErrorCode)) {
                toast.error(t(`claim-error.${error as RetriableErrorCode}`));
              } else if (error) {
                toast.error(error);
              }
            }
            console.error(e);
          },
        },
      );

    isQuestValid &&
      isPreview &&
      testClaimQuest.mutate(taskValues, {
        onSuccess: () => {
          toast.success(t('test-claim.success'));
        },
        onError: e => {
          if (e instanceof FetchError) {
            const error: string = e.response?._data?.error || e.response?._data?.message;

            if (e.response?._data?.taskValidations) {
              setValidationErrors(
                e.response?._data?.taskValidations as ClaimQuestOutput['taskValidations'],
              );
            }

            toast.error(t('test-claim.error'), {
              description: error,
            });
          }
          console.error(e);
        },
      });
  };

  useKeyboardShortcut(
    {
      'cmd+enter': onSubmit,
    },
    {
      enableOnInteractive: true,
    },
  );

  const onRetry = () => {
    claimQuest.reset();
  };

  return (
    <div className="flex flex-1 flex-col bg-secondary overflow-hidden rounded-md relative">
      <div
        className={cx([
          'flex flex-1 px-200 md:px-600 py-400 gap-400 flex-col overflow-y-scroll w-full',
        ])}
      >
        <div className={cx(['flex flex-1 flex-col gap-400 w-full xl:max-w-[682px] mx-auto'])}>
          <div className="flex flex-col gap-100 w-full">
            <h3 className="headings-headline-h2 text-primary">{quest.name || t('placeholder')}</h3>
            <StatusBadges quest={quest} />
          </div>

          <Rewards quest={quest} />
          <RewardMethodDetails quest={quest} />

          <TipTapToContent content={quest.description} />
          <FormProvider {...formMethods}>
            <Tasks {...{ quest }} />
          </FormProvider>
        </div>
      </div>
      {claimQuest.isSuccess && <TrackingPixel />}
      <ClaimActions
        isPreview={!!isPreview}
        quest={quest}
        canClaim={status.canClaim}
        isDisabled={!isQuestValid}
        onClick={onSubmit}
        loading={claimQuest.isPending}
      />

      {(claimQuest.isSuccess ||
        (claimQuest.isError && quest.retryAfter === -1) ||
        !status.canInspect) && <States claimQuest={claimQuest} quest={quest} onRetry={onRetry} />}
    </div>
  );
};

export function WrappedComponent(props: ClaimQuestProps) {
  return (
    <ErrorContext>
      <ClaimQuest {...props} />
    </ErrorContext>
  );
}

export default WrappedComponent;
